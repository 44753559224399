import React from 'react';
import styled from 'styled-components';
import LocalizedLink from '../LocalizedLink/LocalizedLink';
import * as vars from '../../constants';

export const Footer = styled.footer`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: $auto auto;
  padding: ${vars.gridGutter};
  grid-gap: ${vars.gridGutter};
  align-items: center;
  background: ${vars.branding.secondaryDark};
  @media (min-width: ${vars.breakpoints.md}) {
    grid-template-columns: ${vars.asideGutterWidth} ${vars.asideGutterWidth} 1fr 2fr;
  }
  @media (min-width: ${vars.breakpoints.xl}) {
    grid-template-columns: ${vars.asideGutterWidth} ${vars.asideGutterWidth} 3fr 2fr;
  }
`;

export const LogoImage = styled.img`
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column-start: 3;
  }
`;

export const FooterNav = styled.nav`
  display: flex;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  @media (min-width: ${vars.breakpoints.md}) {
    flex-direction: row;
  }
`;

export const FooterLink = styled(props => <LocalizedLink {...props} />)`
  border: none;
  background: transparent;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.75rem;
  &:hover {
    text-decoration: underline;
  }
`;
