/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { IntlProvider, addLocaleData } from 'react-intl';

import './layout.css';
import {
  GlobalGrid,
  TopSpacer,
} from './shared-styled-components/globals.styled';
import GlobalHeader from './GlobalHeader/GlobalHeader.component';
import GlobalFooter from './GlobalFooter/GlobalFooter.component';
import StickyButtonGroup from './StickyButtonGroup/StickyButtonGroup.component';

// Locale data
import enData from 'react-intl/locale-data/en';
import frData from 'react-intl/locale-data/fr';
// Messages
import en from '../i18n/en.json';
import fr from '../i18n/fr.json';
const messages = { en, fr };
addLocaleData([...enData, ...frData]);

const Layout = ({ locale, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <IntlProvider locale={locale} messages={messages[locale]}>
        <Fragment>
          <GlobalGrid>
            <GlobalHeader />
            <main>
              <TopSpacer />
              {children}
            </main>
            <GlobalFooter />
            <StickyButtonGroup />
          </GlobalGrid>
        </Fragment>
      </IntlProvider>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
