export const breakpoints = {
  // xs < 540, base breakpoint
  sm: '540px',
  md: '720px',
  lg: '960px',
  xl: '1280px',
};

export const branding = {
  primary: '#01B5Bf',
  primaryLight: '#02CED9',
  secondary: '#208083',
  secondaryDark: '#105059',
  paleGreen: '#E2F0F1',
  black: '#1E1E1E',
  grey1: '#757575',
  grey2: '#eee',
  grey3: '#F8F8FB',
};

export const asideGutterWidth = '100px';

export const maxWidth = '1440px';
export const gridGutter = '20px';
export const commonColumnWidth = '75px';
export const commonRowHeight = '100px';

export const bodyFont = "'Lato', sans-serif";
