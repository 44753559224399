import React from 'react';
import styled from 'styled-components';
import LocalizedLink from '../LocalizedLink/LocalizedLink';
import * as vars from '../../constants';

export const TopSpacer = styled.div`
  width: 100%;
  height: 50px;
  @media (min-width: ${vars.breakpoints.md}) {
    height: 100px;
  }
`;
export const GlobalGrid = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  min-height: 100%;
  height: 100%;
  @media (min-width: ${vars.breakpoints.md}) {
    padding: 0;
    grid-template-rows: auto 1fr 100px;
  }
`;

export const CommonGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(
    ${p => (p.rowNum ? p.rowNum : 12)},
    ${p => (p.rowSize ? p.rowSize : '75px')}
  );
  grid-gap: ${p => (p.gap ? vars.gridGutter : 0)};
  @media (min-width: ${vars.breakpoints.md}) {
    grid-template-columns:
      100px repeat(
        ${p => (p.columnNum ? p.columnNum : 10)},
        ${p => (p.columnSize ? p.columnSize : '1fr')}
      )
      100px;
  }
`;

export const GlobalWrapper = styled.div`
  max-width: ${vars.breakpoints.xl};
  margin: 0 auto;
  padding: ${p => (p.smFullWidth ? 0 : `0 ${vars.gridGutter}`)};
`;

export const LinkBTN = styled(props => <LocalizedLink {...props} />)`
  display: block;
  background: ${vars.branding.secondaryDark};
  text-align: center;
  flex-shrink: 0;
  color: #fff;
  transition: all 250ms ease-out;
  padding: 20px 15px;
  width: 50%;
  text-decoration: none;

  @media (min-width: ${vars.breakpoints.md}) {
    width: auto;
    min-width: 150px;
    padding: 20px 50px;
  }

  &:hover {
    background: ${vars.branding.secondary};
    cursor: pointer;
  }

  &.inverseColor {
    // cannot apply props for styling {Link} from Gatby components
    background-color: #fff;
    color: ${vars.branding.secondaryDark};

    &:hover {
      background-color: ${vars.branding.grey3};
      color: ${vars.branding.secondary};
    }
  }
`;

export const Button = styled.button`
  display: inline-block;
  background-color: ${vars.branding.secondaryDark};
  color: ${vars.branding.paleGreen};
  border: none;
  text-decoration: none;
  font-weight: bold;
  padding: ${p => (p.large ? '35px' : '20px')};

  &:hover {
    background: ${vars.branding.secondary};
    cursor: pointer;
  }
`;

export const HR = styled.hr`
  margin: ${p => (p.margin ? p.margin : 0)};
  border: 0;
  height: 1px;
  background: ${p => (p.borderColor ? p.borderColor : vars.branding.grey1)};
  width: ${p => (p.width ? p.width : '100%')};
`;

export const GatsbyLink = styled(props => <LocalizedLink {...props} />)`
  color: ${vars.branding.primaryLight};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
