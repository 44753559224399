import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as el from './GlobalFooter.styled';
import logo from '../../images/logo-white.svg';

export default function GlobalFooter() {
  let intervalId = 0;

  const scrollStep = () => {
    if (window.pageYOffset === 0) {
      clearInterval(intervalId);
    }
    window.scroll(0, window.pageYOffset - 50);
  };

  const goToTop = e => {
    e.preventDefault();
    intervalId = setInterval(scrollStep, 16.66);
  };

  return (
    <el.Footer>
      <el.LogoImage src={logo} />
      <el.FooterNav>
        <el.FooterLink to="/articles/">
          <FormattedMessage id="FooterLink.articles" />
        </el.FooterLink>
        <el.FooterLink to="/events/">
          <FormattedMessage id="FooterLink.events" />
        </el.FooterLink>
        <el.FooterLink to="/team/">
          <FormattedMessage id="FooterLink.team" />
        </el.FooterLink>
        <el.FooterLink to="/jobs/">
          <FormattedMessage id="FooterLink.roles" />
        </el.FooterLink>
        <el.FooterLink to="/meet/">
          <FormattedMessage id="FooterLink.coffee" />
        </el.FooterLink>
        <el.FooterLink as="button" onClick={goToTop}>
          <FormattedMessage id="FooterLink.top" />
        </el.FooterLink>
      </el.FooterNav>
    </el.Footer>
  );
}
