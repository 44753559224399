import React, { Component } from 'react';
import * as el from './StickyButtonGroup.styled';
import { Button } from '../shared-styled-components/globals.styled';

export default class StickyButtonGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let scrollTop = window.scrollY;
    let docHeight = this.getDocHeight();
    let showButton =
      scrollTop > 200 && scrollTop + window.innerHeight < docHeight - 200;
    this.setState({ showButton });
  };

  getDocHeight = () => {
    var D = document;
    return Math.max(
      D.body.scrollHeight,
      D.documentElement.scrollHeight,
      D.body.offsetHeight,
      D.documentElement.offsetHeight,
      D.body.clientHeight,
      D.documentElement.clientHeight
    );
  };

  render() {
    return (
      <el.ButtonGroup showButton={this.state.showButton}>
        <Button large as="a" href="http://facebook.com" target="_blank">
          Facebook
        </Button>
        <Button large as="a" href="http://medium.com" target="_blank">
          Medium
        </Button>
      </el.ButtonGroup>
    );
  }
}
