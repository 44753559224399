import React, { useState } from 'react';
import * as el from './GlobalHeader.styled';
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';
import locales from '../../constants/locales';
import LocalizedLink from '../LocalizedLink/LocalizedLink';

function GlobalNavigationLinks() {
  return (
    <>
      <el.GlobalNavLink to="/articles/">
        <FormattedHTMLMessage id="GlobalNavigationLinks.articles" />
      </el.GlobalNavLink>
      <el.GlobalNavLink to="/events/">
        <FormattedHTMLMessage id="GlobalNavigationLinks.events" />
      </el.GlobalNavLink>
      <el.GlobalNavLink to="/team/">
        <FormattedHTMLMessage id="GlobalNavigationLinks.team" />
      </el.GlobalNavLink>
    </>
  );
}

// function LanguageControls(props) {
//   if (typeof window !== 'undefined') {
//     const getUrl = (currentLocale, switchToLocale) => {
//       const currentUrlPath = window.location.pathname;
//       if (currentLocale === switchToLocale) {
//         return currentUrlPath;
//       }

//       if (currentLocale === 'en' && switchToLocale === 'fr') {
//         return `/fr/${currentUrlPath}`;
//       } else if (currentLocale === 'fr' && switchToLocale === 'en') {
//         return currentUrlPath.replace('fr/', '');
//       }
//     };

//     return (
//       <el.LanguageContainer>
//         <el.LanguageButton to={getUrl(props.locale, 'en')}>
//           EN
//         </el.LanguageButton>
//         <el.SeperatorSpan>|</el.SeperatorSpan>
//         <el.LanguageButton to={getUrl(props.locale, 'fr')}>
//           FR
//         </el.LanguageButton>
//       </el.LanguageContainer>
//     );
//   }
// }

function LanguageControls(props) {
  return (
    <el.LanguageContainer>
      {Object.keys(props.locales).map((localeObj, index) =>
        props.locales[localeObj].locale === 'EN' ? (
          <>
            <el.LanguageButton
              localeObj={localeObj}
              key={localeObj}
              locales={props.locales}
              locale={props.locale}
            />{' '}
            <el.SeperatorSpan>|</el.SeperatorSpan>
          </>
        ) : (
          <el.LanguageButton
            localeObj={localeObj}
            key={localeObj}
            locales={props.locales}
            locale={props.locale}
          />
        )
      )}
    </el.LanguageContainer>
  );
}

const GlobalHeader = ({ intl: { locale } }) => {
  const [isMobileMenuOpen, setMenuState] = useState(false);
  return (
    <>
      <el.HeaderContainer>
        <el.LogoLinkContainer mobileMenuOpen={isMobileMenuOpen}>
          <LocalizedLink to="/" />
        </el.LogoLinkContainer>
        <el.NavigationMenu>
          <el.DesktopNavigationContainer>
            <GlobalNavigationLinks />
            <LanguageControls locales={locales} locale={locale} />
          </el.DesktopNavigationContainer>
          <el.MobileMenuButton
            aria-label="Open mobile menu"
            onClick={() => setMenuState(!isMobileMenuOpen)}
            mobileMenuOpen={isMobileMenuOpen}
          />
        </el.NavigationMenu>
      </el.HeaderContainer>
      <el.MobileNavigationContainer
        aria-hidden={!isMobileMenuOpen}
        mobileMenuOpen={isMobileMenuOpen}
      >
        <el.MobileLinksContainer>
          <GlobalNavigationLinks />
        </el.MobileLinksContainer>
        <LanguageControls locales={locales} locale={locale} />
      </el.MobileNavigationContainer>
    </>
  );
};

GlobalHeader.propTypes = { intl: intlShape.isRequired };

export default injectIntl(GlobalHeader);
